import { Button, Stack, Tooltip } from 'components';
import { Formik, FormikHelpers } from 'formik';
import Checkbox from 'modules/myTodos/components/checkbox/checkbox';
import { useState } from 'react';
import { CommonTodo, Todo as TodoType } from 'store/myTodos/myTodosSlice';
import { AnimatePresence, motion } from 'framer-motion';
import { validationSchema } from 'modules/myTodos/utils/validation';
import dayjs from 'dayjs';
import { ClickAwayListener } from '@mui/material';
import { clearNewlyCreated } from 'store/myTodos/myTodosSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getLsUserRoleObject } from 'modules/networkTools/localStorageTokens';
import clsx from 'clsx';
import {
  deleteTodoDraft,
  patchUpdateTodoStatus,
  putUpdateTodo
} from 'store/myTodos/myTodosActions';
import { mapDates } from 'modules/myTodos/utils/date';
import { isOverdue } from 'store/myTodos/selectors';

import style from './todo.module.scss';
import TodoActions from './todoActions';
import { TitleInput } from '../titleInput';
import { Info } from './info';

type Props = {
  data: TodoType;
  exitAnimation?: boolean;
};

const hasDifferentEndDate = (
  startDateTime: string | undefined | null,
  endDateTime: string | undefined | null
) => {
  if (!endDateTime) {
    return false;
  }

  if (!endDateTime && !startDateTime) {
    return false;
  }

  return !dayjs(startDateTime).isSame(endDateTime, 'date');
};

const getTodoValues = (todo: CommonTodo) => {
  return {
    ...todo,
    date:
      todo.startDateTime || todo.date
        ? dayjs(todo.startDateTime ?? todo.date).format('YYYY-MM-DD')
        : null,
    endDate: todo.endDateTime ? dayjs(todo.endDateTime).format('YYYY-MM-DD') : null,
    startTime: todo.startDateTime ? dayjs(todo.startDateTime).format('HH:mm') : null,
    endTime: todo.endDateTime ? dayjs(todo.endDateTime).format('HH:mm') : null,
    hasDifferentEndDate: hasDifferentEndDate(todo.startDateTime, todo.endDateTime)
  };
};

const getInitialValues = (todo: TodoType) => {
  const { userId } = getLsUserRoleObject();

  if (todo.draft && todo.draft.userId === userId && todo.draft.creationContext === 'MY_TODOS') {
    return getTodoValues(todo.draft);
  }

  return getTodoValues(todo);
};

export type TodoInitialValues = ReturnType<typeof getInitialValues>;

const Todo = ({ data, exitAnimation = true }: Props) => {
  const { userId } = getLsUserRoleObject();
  const hasOpenedPopper = useAppSelector((state) => state.myTodos.hasOpenedPopper);
  const hasDraft = Boolean(data.draft);
  const isTodoBeingEditedByAnotherUser = hasDraft && data.draft?.userId !== userId;
  const isTodoBeingEditedByCurrentUser =
    hasDraft && data.draft?.userId === userId && data.draft?.creationContext === 'MY_TODOS';

  const isTodoBeingEditedInRoadmaps = hasDraft && data.draft?.creationContext === 'ROADMAPS';
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(isTodoBeingEditedByCurrentUser);

  const newlyCreated = data.newlyCreated;

  const tooltipText = `${data.draft?.userName} is editing this Roadmap so you can't change it right now`;

  const onSubmit = async (
    values: TodoInitialValues,
    formikHelpers: FormikHelpers<TodoInitialValues>
  ) => {
    const {
      date: newDate,
      startDateTime,
      endDateTime
    } = mapDates({
      date: values.date,
      endDate: values.endDate,
      startTime: values.startTime,
      endTime: values.endTime,
      hasDifferentEndDate: values.hasDifferentEndDate
    });
    if (data.id) {
      await dispatch(
        putUpdateTodo({
          title: values.title,
          clientId: values.client?.id,
          goalId: values.goalId,
          note: values.note,
          location: values.location,
          color: values.color,
          startDateTime,
          endDateTime,
          date: newDate,
          status: data.status,
          id: data.id
        })
      );
      formikHelpers.resetForm({ values });
    }
  };

  const onCancel = () => {
    if (data.id) {
      dispatch(deleteTodoDraft({ id: data.id }));
    }
  };

  return (
    <Formik
      initialValues={getInitialValues(data)}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, dirty, resetForm, isValid, handleSubmit, isSubmitting }) => {
        if (data.title?.startsWith('Q3')) {
          console.log({ dirty, isTodoBeingEditedByCurrentUser });
        }
        return (
          <ClickAwayListener
            onClickAway={() => {
              if (dirty || isTodoBeingEditedByCurrentUser || hasOpenedPopper) {
                return;
              }

              setIsOpen(false);
            }}
          >
            <motion.div layout style={{ width: '100%' }}>
              <form onSubmit={handleSubmit}>
                <motion.div
                  layout
                  initial={newlyCreated ? { opacity: 0, x: '-110%' } : { opacity: 1, x: 0 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.2, type: 'spring', bounce: 0 }}
                  onAnimationComplete={() => {
                    console.log('clearNewlyCreated', data.id);
                    console.log({ newlyCreated });
                    if (newlyCreated && data.id) {
                      dispatch(clearNewlyCreated(data.id));
                    }
                  }}
                >
                  <Stack
                    display="flex"
                    onClick={() => setIsOpen(true)}
                    className={style.wrapper}
                    px="2px"
                    data-open={isOpen}
                    style={{ overflow: 'hidden' }}
                  >
                    <Stack display="flex" flexDirection="row" gap="14px" position="relative">
                      <Stack width="4px" bgcolor={values.color} />
                      <Stack display="flex" py="8px" width="100%">
                        <Stack width="100%" display={'flex'} flexDirection="row">
                          <Stack display={'flex'} flexDirection="row" alignItems="start" gap="10px">
                            <Stack ml="3px" width="fit-content">
                              {isTodoBeingEditedByAnotherUser || isTodoBeingEditedInRoadmaps ? (
                                <Tooltip
                                  toolTipText={tooltipText}
                                  styles={{
                                    maxWidth: '289px',
                                    textAlign: 'center'
                                  }}
                                >
                                  <span className={clsx('material-icons', style.lockIcon)}>
                                    lock
                                  </span>
                                </Tooltip>
                              ) : (
                                <Checkbox
                                  value={data.status === 'COMPLETED'}
                                  disabled={
                                    dirty ||
                                    data.status === 'ABANDONED' ||
                                    isTodoBeingEditedByCurrentUser
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(e) => {
                                    if (!data.id) return;
                                    const checked = e.target.checked;
                                    dispatch(
                                      patchUpdateTodoStatus({
                                        id: data.id,
                                        status: checked ? 'COMPLETED' : 'ACTIVE'
                                      })
                                    );
                                  }}
                                />
                              )}
                            </Stack>
                          </Stack>
                          <Stack display="flex" flexDirection="column" gap="4px" flexGrow={1}>
                            <TitleInput
                              expandable={true}
                              disabled={
                                data.status === 'ABANDONED' ||
                                data.status === 'COMPLETED' ||
                                isTodoBeingEditedByAnotherUser ||
                                isTodoBeingEditedInRoadmaps
                              }
                              className={style.todoInput}
                              data-status={data.status}
                              data-overdue={isOverdue(data)}
                              wrapperClassName={style.titleInputWrapper}
                            />
                            <Info data={data} />
                          </Stack>
                        </Stack>
                        <AnimatePresence initial={false}>
                          {isOpen && (
                            <motion.div
                              style={{
                                position: 'relative'
                              }}
                              initial={{ height: 0 }}
                              animate={{ height: 'auto' }}
                              exit={{ height: 0 }}
                            >
                              <TodoActions todo={data} />
                            </motion.div>
                          )}
                        </AnimatePresence>
                        <AnimatePresence initial={false}>
                          {(dirty || isTodoBeingEditedByCurrentUser) && (
                            <motion.div
                              layout
                              initial={{ opacity: 0, height: 0 }}
                              animate={{ opacity: 1, height: 'auto' }}
                              exit={{ opacity: 0, height: 0 }}
                            >
                              <Stack display="flex" flexDirection="row" gap="8px" marginTop="10px">
                                <Button
                                  handleOnClick={(e) => {
                                    e.stopPropagation();
                                    resetForm({ values: getTodoValues(data) });
                                    onCancel();
                                  }}
                                  text="Cancel"
                                  theme="transparent"
                                  size="extraSmall"
                                  type="button"
                                />
                                <Button
                                  text="Save"
                                  theme="primary"
                                  size="extraSmall"
                                  disabled={!isValid || isSubmitting}
                                  type="submit"
                                />
                              </Stack>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </Stack>
                    </Stack>
                  </Stack>
                </motion.div>
              </form>
            </motion.div>
          </ClickAwayListener>
        );
      }}
    </Formik>
  );
};

export default Todo;
