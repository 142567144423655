import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getLsUserRoleObject,
  getRoadmapsConfig,
  setRoadmapsConfig
} from 'modules/networkTools/localStorageTokens';
import { notifyUserError } from 'utils/notifications';

import {
  postCreateTodo,
  getTodos,
  patchUpdateTodoStatus,
  putUpdateTodo,
  getGoals,
  updateTodoOrder,
  updateGoalOrder,
  putNewTodoDraft,
  getNewTodoDraft,
  deleteNewTodoDraft,
  postCreateGoal,
  putNewGoalDraft,
  deleteNewGoalDraft
} from './roadmapsActions';

export type View = 'todos' | 'goals';

export type Status = 'ACTIVE' | 'COMPLETED' | 'ABANDONED';

export type CommonTodo = {
  updatedAt?: string;
  createdAt?: string;
  id: number | null;
  goalId: number | null;
  title: string;
  status: 'ACTIVE' | 'COMPLETED' | 'ABANDONED';
  date?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  location: string | null;
  note: string | null;
  color: string;
  orderNum?: number | null;
  client: {
    name: string;
    id: number;
  } | null;
};

type DraftTodo = CommonTodo & {
  creationContext: 'ROADMAPS' | 'MY_TODOS';
  userId: number;
  userName: string;
};

export type Todo = CommonTodo & {
  draft: DraftTodo | null;
  newlyCreated?: boolean;
  statusUpdatedAt: string;
};

type CommonGoal = {
  id: number | string;
  title: string;
  date: string | null;
};

type Goal = CommonGoal & {
  todos: Todo[];
  draft: CommonGoal | null;
  newTodoDraft: DraftTodo | null;
  status: Status;
  expanded: boolean;
  orderNum: number;
};

type InitialState = {
  view: View;
  status: Status[];
  hasOpenedPopper: boolean;
  todos: Todo[];
  goals: Goal[];
  newTodoDraft: DraftTodo | null;
  newGoalDraft: CommonGoal | null;
};

const uncategorizedGoal: Goal = {
  id: 'uncategorizedGoal',
  title: 'No goal',
  todos: [],
  draft: null,
  newTodoDraft: null,
  date: null,
  status: 'ACTIVE',
  expanded: false,
  orderNum: 0
};

const initialState: InitialState = {
  view: getRoadmapsConfig().view || 'goals',
  status: getRoadmapsConfig().status || ['ACTIVE'],
  newTodoDraft: null,
  newGoalDraft: null,
  hasOpenedPopper: false,
  todos: [],
  goals: [uncategorizedGoal]
};

export const roadmapsSlice = createSlice({
  name: 'roadmapsSlice',
  initialState,
  reducers: {
    setView: (state, action) => {
      state.view = action.payload;
      const goalsExpanded = state.goals.reduce((acc, goal) => {
        acc[goal.id] = goal.expanded;
        return acc;
      }, {} as Record<string, boolean>);
      setRoadmapsConfig({ view: action.payload, status: state.status, goalsExpanded });
    },
    setStatus: (state, action) => {
      state.status = action.payload;
      const goalsExpanded = state.goals.reduce((acc, goal) => {
        acc[goal.id] = goal.expanded;
        return acc;
      }, {} as Record<string, boolean>);
      setRoadmapsConfig({ view: state.view, status: action.payload, goalsExpanded });
    },
    setHasOpenedPopper: (state, action: PayloadAction<{ opened: boolean }>) => {
      state.hasOpenedPopper = action.payload.opened;
    },
    updateTodo: (state, action: PayloadAction<{ todo: Todo }>) => {
      const { todo } = action.payload;
      const index = state.todos.findIndex((t) => t.id === todo.id);
      if (index !== -1) {
        state.todos[index] = todo;
      }
    },
    setGoalExpanded: (
      state,
      action: PayloadAction<{ goalId: string | number | null; expanded: boolean }>
    ) => {
      if (action.payload.goalId) {
        const goalIndex = state.goals.findIndex((goal) => goal.id === action.payload.goalId);
        if (goalIndex !== -1) {
          state.goals[goalIndex].expanded = action.payload.expanded;
        }
      } else {
        const uncategorizedGoalIndex = state.goals.findIndex((goal) => goal.id == null);
        if (uncategorizedGoalIndex !== -1) {
          state.goals[uncategorizedGoalIndex].expanded = action.payload.expanded;
        }
      }

      const goalsExpanded = state.goals.reduce((acc, goal) => {
        acc[goal.id] = goal.expanded;
        return acc;
      }, {} as Record<string, boolean>);

      setRoadmapsConfig({
        view: state.view,
        status: state.status,
        goalsExpanded
      });
    },
    setAllGoalsExpanded: (state, action: PayloadAction<boolean>) => {
      state.goals.forEach((goal) => {
        goal.expanded = action.payload;
      });
    },
    clearNewlyCreated: (state, action: PayloadAction<number>) => {
      const todo = state.todos.find((t) => t.id === action.payload);
      if (todo) {
        todo.newlyCreated = false;
      }
    },
    createNewTodo: (state, action: PayloadAction<{ goalId: number }>) => {
      const goalIndex = state.goals.findIndex((g) => g.id === action.payload.goalId);
      if (goalIndex !== -1) {
        state.goals[goalIndex].newTodoDraft = {
          creationContext: 'ROADMAPS',
          goalId: action.payload.goalId,
          title: '',
          status: 'ACTIVE',
          orderNum: 0,
          id: 0,
          client: null,
          userId: 0,
          userName: '',
          date: null,
          startDateTime: null,
          endDateTime: null,
          location: null,
          note: null,
          color: '#03A59D'
        };
      }
    },
    clearNewTodoGoalDraft: (state, action: PayloadAction<number>) => {
      const goal = state.goals.find((g) => g.id === action.payload);
      if (goal) {
        goal.newTodoDraft = null;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTodos.fulfilled, (state, { payload }) => {
      state.todos = payload;
    });
    builder
      .addCase(postCreateTodo.fulfilled, (state, { payload }) => {
        let smallestOrderNum: undefined | number;

        const activeTodos = state.todos.filter((todo) => todo.status === 'ACTIVE');

        if (activeTodos.length === 0) {
          smallestOrderNum = 2;
        } else {
          smallestOrderNum = Math.min(...activeTodos.map((todo) => todo.orderNum ?? 0));
        }

        state.todos.push({ ...payload, newlyCreated: true, orderNum: smallestOrderNum - 1 });
        state.newTodoDraft = null;
      })
      .addCase(patchUpdateTodoStatus.pending, (state, { payload, meta }) => {
        const index = state.todos.findIndex((todo) => todo.id === meta.arg.id);
        if (index !== -1) {
          state.todos[index].status = meta.arg.status;
          state.todos[index].statusUpdatedAt = new Date().toISOString();
        }

        if (meta.arg.status === 'ACTIVE') {
          const activeTodos = state.todos.filter((todo) => todo.status === 'ACTIVE');
          let smallestOrderNum: undefined | number;
          if (activeTodos.length === 0) {
            smallestOrderNum = 2;
          } else {
            smallestOrderNum = Math.min(...activeTodos.map((todo) => todo.orderNum ?? 0));
          }

          if (index !== -1) {
            state.todos[index].orderNum = smallestOrderNum - 1;
          }
        }
      })
      .addCase(putUpdateTodo.fulfilled, (state, { payload }) => {
        const index = state.todos.findIndex((todo) => todo.id === payload.id);
        if (index !== -1) {
          state.todos[index] = { ...payload, draft: null };
        }
      })
      .addCase(getGoals.pending, (state) => {
        state.goals = [uncategorizedGoal];
      })
      .addCase(getGoals.fulfilled, (state, { payload }) => {
        const goals = payload
          .map((goal: Goal) => ({
            ...goal,
            expanded: getRoadmapsConfig().goalsExpanded?.[goal.id] || false,
            title: goal.id ? goal.title : 'No goal'
          }))
          .filter((goal: Goal) => goal.id);

        state.goals = [...state.goals, ...goals];
      })
      .addCase(updateTodoOrder.pending, (state, { meta }) => {
        meta.arg.todos.forEach((todo) => {
          const index = state.todos.findIndex((t) => t.id === todo.id);
          if (index !== -1) {
            state.todos[index].orderNum = todo.orderNum;
          }
        });
      })
      .addCase(updateGoalOrder.pending, (state, { meta }) => {
        meta.arg.goals.forEach((goal) => {
          const index = state.goals.findIndex((g) => g.id === goal.id);
          if (index !== -1) {
            state.goals[index].orderNum = goal.orderNum;
          }
        });
      })
      .addCase(putNewTodoDraft.fulfilled, (state, { payload, meta }) => {
        state.newTodoDraft = {
          id: payload.id,
          client: payload.client,
          title: meta.arg.title,
          status: 'ACTIVE',
          startDateTime: meta.arg.startDateTime,
          endDateTime: meta.arg.endDateTime,
          date: meta.arg.date,
          location: meta.arg.location,
          note: meta.arg.note,
          color: meta.arg.color,
          creationContext: meta.arg.creationContext,
          userId: getLsUserRoleObject().userId,
          goalId: meta.arg.goalId,
          userName: getLsUserRoleObject().name
        };
      })
      .addCase(getNewTodoDraft.fulfilled, (state, { payload }) => {
        if (payload == null || payload === '') {
          state.newTodoDraft = null;
          return;
        }
        state.newTodoDraft = payload;
      })
      .addCase(deleteNewTodoDraft.pending, (state) => {
        state.newTodoDraft = null;
      })
      .addCase(deleteNewTodoDraft.fulfilled, (state) => {
        state.newTodoDraft = null;
      })
      .addCase(putNewTodoDraft.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(postCreateGoal.fulfilled, (state, { payload }) => {
        // find lowest goal order num
        const lowestOrderNum = Math.min(...state.goals.map((goal) => goal.orderNum ?? 0));
        state.goals.push({
          ...payload,
          orderNum: lowestOrderNum - 1,
          expanded: false,
          draft: null
        });
      })
      .addCase(putNewGoalDraft.fulfilled, (state, { payload }) => {
        state.newGoalDraft = payload;
      })
      .addCase(putNewGoalDraft.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(deleteNewGoalDraft.fulfilled, (state) => {
        state.newGoalDraft = null;
      });
  }
});

export const {
  setView,
  setStatus,
  setHasOpenedPopper,
  updateTodo,
  setGoalExpanded,
  setAllGoalsExpanded,
  clearNewlyCreated,
  createNewTodo,
  clearNewTodoGoalDraft
} = roadmapsSlice.actions;

export default roadmapsSlice.reducer;
