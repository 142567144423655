import { useLayoutEffect } from 'react';

export function useLockBodyScroll({ lock }: { lock: boolean }) {
  useLayoutEffect(() => {
    if (lock) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.documentElement.style.overflow = '';
      document.body.style.overflow = '';
    };
  }, [lock]);
}
