import { Stack } from 'components';
import { getLsUserRoleObject } from 'modules/networkTools/localStorageTokens';
import { useAppSelector } from 'store/hooks';
import { Todo as TodoType } from 'store/myTodos/myTodosSlice';
import {
  useFutureTodos,
  useNoDateTodos,
  useOverdueTodos,
  usePastTodos
} from 'store/myTodos/selectors';

import { Todo } from '../components/Todo';
import { getDayListSectionTitle, NO_DATE_TITLE, OVERDUE_TITLE } from '../utils/sectionUtils';
import DayListSection from './dayListSection';

const haveADraft = ({ todo, userId }: { todo: TodoType; userId: any }) =>
  Boolean(todo.draft) &&
  todo.draft?.userId === userId &&
  todo.draft?.creationContext === 'MY_TODOS';

const DayList = () => {
  const todos = useAppSelector((state) => state.myTodos.todos.data);
  const { userId } = getLsUserRoleObject();

  const pastTodos = usePastTodos();
  const noDateTodos = useNoDateTodos();
  const overdueTodos = useOverdueTodos();
  const futureTodos = useFutureTodos();

  if (!todos) return null;

  return (
    <Stack
      flexGrow="1"
      style={{
        overflowY: 'scroll',
        overflowX: 'hidden',
        flexGrow: 1
      }}
      marginBottom="20px"
      boxSizing="border-box"
    >
      <Stack display="flex" minHeight={0}>
        {pastTodos.map((item) => (
          <DayListSection
            key={item.date}
            title={getDayListSectionTitle(item.date as string)}
            expanded={item.todos.some(
              (todo) => Boolean(todo.draft) && todo.draft?.userId === userId
            )}
            disabled={item.todos.some((todo) => haveADraft({ todo, userId }))}
          >
            {item.todos.map((todo) => (
              <Todo key={todo.id} data={todo} />
            ))}
          </DayListSection>
        ))}
        {noDateTodos.length > 0 && (
          <DayListSection
            title={NO_DATE_TITLE}
            expanded={noDateTodos.some(
              (todo) => Boolean(todo.draft) && todo.draft?.userId === userId
            )}
            disabled={noDateTodos.some((todo) => haveADraft({ todo, userId }))}
          >
            {noDateTodos.map((todo) => (
              <Todo key={todo.id} data={todo} />
            ))}
          </DayListSection>
        )}
        {overdueTodos.length > 0 && (
          <DayListSection
            title={OVERDUE_TITLE}
            expanded={overdueTodos.some(
              (todo) => Boolean(todo.draft) && todo.draft?.userId === userId
            )}
            disabled={overdueTodos.some((todo) => haveADraft({ todo, userId }))}
          >
            {overdueTodos.map((todo) => (
              <Todo key={todo.id} data={todo} />
            ))}
          </DayListSection>
        )}
        {futureTodos.map((item) => (
          <DayListSection
            key={item.date}
            title={getDayListSectionTitle(item.date as string)}
            expanded={item.todos.some(
              (todo) => Boolean(todo.draft) && todo.draft?.userId === userId
            )}
            disabled={item.todos.some((todo) => haveADraft({ todo, userId }))}
          >
            {item.todos.map((todo) => (
              <Todo key={todo.id} data={todo} />
            ))}
          </DayListSection>
        ))}
      </Stack>
    </Stack>
  );
};

export default DayList;
